<template>
   <div class="password-container border p-5">
     <div class="text-center mb-3"><img class="logo" src="@/assets/logo_pm_hdr.png" /></div>
    <div v-if="!success">
      <h1 class="text-center mb-3">New Password</h1>
      <div v-if="!new_user" class="mb-4"><i class="text-danger">Resetting your password will remove any existing messages</i></div>
      <div class="mb-3">
          <label for="password" class="form-label">New Password</label>
          <input @focus="reset" type="password" v-model="password" class="form-control" id="password" placeholder="New Password">
      </div>
      <div class="mb-3">
          <label for="password-confirm" class="form-label">Confirm New Password</label>
          <input @focus="reset" type="password" ref="confirmPass" v-model="confirmPass" class="form-control" id="password-confirm" placeholder="Confirm New Password">
      </div>
      <div class="card mb-3">
        <div class="card-body">
        Your password must contain:
        <ul>
        <li>At least 8 characters</li>
        <li>At least one uppercase letter (A-Z)</li>
        <li>At least one lowercase letter (a-z)</li>
        <li>At least one digit (0-9)</li>
        <li>At least one special character</li>
        </ul>
        </div>
      </div>
      <div class="text-center">
          <button v-if="!clicked" @click="setPassword" class="btn btn-primary">Change Password</button>
          <div v-else>
            <div class="spinner-border text-primary" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
        </div>
      </div>
      <div v-if="error" class="text-danger text-center text-wrap mt-2" style="max-width: 544px">{{error_message}}</div>
    </div>
    <div v-else class="text-center">
      <h3>Success!</h3>
      <div class="my-2">Your password has been set</div>
      <router-link to="/login" class="btn btn-primary">Login</router-link>
    </div>
  </div>
</template>

<script>
import authHeader from '../services/auth-header';
export default {
  name: 'ForgotPassword',
  data: function() {
    return {
      userId: '',
      admin: false,
      messages: null,
      modal: null,
      password: "",
      confirmPass: "",
      error: false,
      error_message: "",
      token: this.$route.query.token,
      success: false,
      new_user: false,
      clicked: false,
    }
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    }
  },
  created(){
    if (this.loggedIn) {
      this.$router.push('/');
    }

    if(this.token == null || this.token  == "") {
      this.$router.push('/');
    }
    else {
      var header = authHeader()
      header['Access-Control-Allow-Origin'] = process.env.VUE_APP_API_BASE_URL
      header["Content-Type"] = 'multipart/form-data'
      axios.post("/api/password/token", {token: this.token}, {withCredentials: true, headers: header}).then((res)=>{
        this.new_user = res.data
      }).catch((err) => {
        this.$router.push('/');
      })
    }


  },
  mounted() {
  },
  methods: {
    setPassword: function() {
        if(this.password == "") {
            this.error = true;
            this.error_message = "Please enter a password"

        }
        else if(this.password != this.confirmPass) {
            this.$refs.confirmPass.classList.add("is-invalid");
            this.error = true;
            this.error_message = "Password does not match"
        }
        else if(this.password.length < 8) {
            this.error = true;
            this.error_message = "Password must be at least 8 characters"
        }
        //one lower, one upper, one digit, one special
        else if(!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(this.password)) {
            this.error = true;
            this.error_message = "Password must contain at least one uppercase letter, one lowercase letter, one digit, and one special character"
        }
        else {
          this.clicked = true
            var header = authHeader()
            header['Access-Control-Allow-Origin'] = process.env.VUE_APP_API_BASE_URL
            header["Content-Type"] = "application/json"
        var self = this;
          axios.post("/api/password", {password: this.password, token: this.token}, {withCredentials: true, headers: header})
          .then((res) => {
            self.success = true
            this.clicked = false
          })
          .catch((err) => {
          this.clicked = false
          this.error = true;
          this.error_message = "Error changing password. Please try again later."
          })
        }
    },
    reset() {
        this.$refs.confirmPass.classList.remove("is-invalid");
        this.error = false;
    },
  }
}
</script>
<style scoped>

.password-container {
     min-width: 20rem;
    width: 100%;
    max-width: 35rem;
    margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
</style>
