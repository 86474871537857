<template>
  <div class="forgot-container border p-5">
         <div class="text-center mb-3"><img class="logo pb-3" src="@/assets/logo_pm_hdr.png" /></div>
    <div v-if="!success">
    <!-- <div class="text-center mb-3"><img class="logo" src="@/assets/PHPA_PRIMARY.png" /></div> -->
    <h1 class="text-center mb-3">Reset Your Password</h1>
    <div class="mb-3">
        <label for="email" class="form-label">Enter your email and we will send you a link to set a new password:</label>
        <input @focus="reset" type="text" v-model="email" class="form-control" id="Email" placeholder="Email">
    </div>
    <div class="text-center">
        <button v-if="!clicked" @click="send" class="btn btn-primary">Send</button>
        <div v-else>
        <div class="spinner-border text-primary" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
        </div>
        <div class="mt-1 text-danger" v-show="error">{{message}}</div>
    </div>
    </div>
    <div class="text-center" v-else>
      <h4>An email has been sent</h4>
      <div class="mb-3">Follow the instructions in the email to change your password.</div>
      <div><router-link to="/login" class="btn btn-primary">Return to login</router-link></div>

    </div>
  </div>
</template>

<script>
import authHeader from '../services/auth-header';
export default {
  name: 'EmailPassword',
  data: function() {
    return {
      email: "",
      loading: false,
      message: "",
      path: "",
      invalid: false,
      error: false,
      success: false,
      clicked: false,
    }

  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    }
  },
  created(){
    if (this.loggedIn) {
      this.$router.push('/');
    }
  },
  methods: {
    send: function() {
      this.clicked = true
      if(this.email != '') {
      var header = authHeader()
      header['Access-Control-Allow-Origin'] = process.env.VUE_APP_API_BASE_URL
      header["Content-Type"] = "application/json"
      var self = this;
      axios.post("/api/password/send", {email: this.email}, {withCredentials: true, headers: header})
      .then((res) => {
        this.success = true;
        this.clicked = false;
      })
      .catch((err) => {
        this.error = true;
        this.message = "There was an error. Please make sure you are using a valid email."
        this.clicked = false
      })
      }
      else {
        this.message = "Please enter a valid email"
        this.error = true;
        this.clicked = false;
      }
    },
      reset() {
    this.error = false;
    this.message = "";
  }
  }
}
</script>
<style>
.forgot-container {
    min-width: 20rem;
    width: 100%;
    max-width: 40em;
    margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

/* .logo {
  max-width: 15rem;
} */
</style>
