<template>
  <div class="login-container border p-5">
    <div class="text-center mb-4"><img class="logo" src="@/assets/logo_pm_hdr.png" /></div>
    <!-- <h1 class="text-center mb-3">Login</h1> -->
    <div class="mb-3">
        <label for="username" class="form-label">Username</label>
        <input @focus="reset" type="text" v-model="username" class="form-control" id="username" placeholder="Username">
    </div>
    <div class="mb-3">
        <label for="password" class="form-label">Password</label>
        <input @focus="reset" type="password" v-model="password" class="form-control" id="password" placeholder="Password">
    </div>
    <div class="text-center">
        <button @click="login" class="btn btn-primary">Login</button>
        <div class="mt-1 text-danger" v-show="invalid">{{ errorMessage }}</div>
        <div class="mt-1 text-danger" v-show="empty">Please enter a username and/or password</div>
        <div class="mt-3"><router-link to="/password-reset" style="text-decoration: none"><small>Click here if you are a new user or you forgot your password.</small></router-link></div>
         <div class="mt-1"><small>Need assistance? Call <a class="phone" href="tel:905-354-9393">905-354-9393</a> for help.</small></div>
    </div>
  </div>
</template>

<script>
import authHeader from '../services/auth-header';
export default {
  name: 'Login',
  data: function() {
    return {
      username: "",
      password: "",
      loading: false,
      message: "",
      path: "",
      invalid: false,
      empty: false,
      errorMessage: ""
    }

  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    }
  },
  created(){
    // axios.get("/api/status", {withCredentials: true, headers: authHeader()})
    if (this.loggedIn) {
      this.$router.push('/');
    }
  },
  mounted() {
        axios.get("/api/status", {withCredentials: true, headers: authHeader()})
  },
  methods: {
    login: function() {
      // var fd = new FormData();
      // fd.append('username', this.username);
      // fd.append('password', this.password);
      // let self = this;
      // axios.post("/api/login",fd, {withCredentials: true}).then(function (response) {
      // if(response.status == 200) {
      //     self.$router.push("/")
      //   }
      // })
      if(this.username && this.password) {
        this.$store.dispatch('auth/login', { username: this.username, password: this.password }).then(
          (res) => {
            if(res){
            if(res.error) {
              this.invalid = true;
              this.errorMessage = res.error_message
            }
            else {
              this.$router.push("/")
            }
            }
          },
        );
      }
      else {
        this.empty = true;
      }
    },
      reset() {
    this.empty = false;
    this.invalid = false;
  }
  }
}
</script>
<style>
.login-container {
    min-width: 20rem;
    width: 100%;
    max-width: 40rem;
    margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.logo {
  width: 100%;
  max-width: 28rem;
}
</style>
