<template>
   <div class="maintenance-container p-5">
       <div class="text-center mb-2"><img class="logo" src="@/assets/logo_pm_hdr.png" /></div>
       <h1 class="text-center">Portage Medical Family Health Team Secure Messaging Will Be Back Soon</h1>
       <p class="text-center">We are down for maintenance but will be back in no time!</p>
  </div>
</template>

<script>
import authHeader from '../services/auth-header';
export default {
  name: 'Maintenance',
  data: function() {
    return {
    }
  },
  computed: {
  },
  created(){
    axios.get("/api/status", {withCredentials: true, headers: authHeader()}).then((res) => {
      // if(res.status = 200) {
      //   this.$router.push('/');
      // }
    })
  },
  mounted() {
  },
  methods: {
  }
}
</script>
<style scoped>

.maintenance-container {
    min-width: 30rem;
    margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

</style>
