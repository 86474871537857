<template>
  <div>
    <div class="nb mb-4">
      <div style="float: left; width: 30%;">
        <div style="width: 200px">
        <router-link to="/"><img class="nav-img" src="@/assets/logo_pm_hdr.png" /></router-link>
        <div class="text-end">
          <a class="phone" style="" href="tel:905-354-9393">905-354-9393</a>
        </div>
        </div>
      </div>
      <!-- DESKTOP NAV -->
      <div class="nb-right d-none d-lg-block" style="vertical-align: middle">
        <div v-if="admin" class="me-3 mb-2">Logged in as <strong>{{ user_name }}</strong></div>
        <ul class="nav-ul">
        <li v-if="!admin" style="white-space:nowrap">Logged in as <strong>{{ user_name }}</strong></li>
        <li><a v-if="this.admin" @click="msgModal.show()" style="white-space:nowrap">New Message</a></li>
        <li><router-link style="text-decoration: none" v-if="this.admin" to="/archived">Archived</router-link></li>
        <!-- <a v-if="this.admin" @click="userModal.show()">Create User</a> -->
         <li><router-link style="text-decoration: none; white-space:nowrap" to="/change-password">Change Password</router-link></li>
        <li><a @click="logout">Logout</a></li>
        </ul>
      </div>
      <!-- MOBILE NAV -->
      <div class="d-lg-none" style="width: 70%; float: right; text-align: right;">
        <a data-bs-toggle="collapse"
          data-bs-target="#mobileNav"
          aria-controls="mobileNav"
          aria-expanded="false"
          aria-label="Toggle navigation"><mobile class="menu" /></a>
      </div>
    </div>
    <div class="collapse collaps-horizontal mb-4" id="mobileNav">
          <ul class="navbar-nav ms-auto mb-2 mb-lg-0 text-center">
            <li class="nav-item m-2 text-center">Logged in as <strong>{{ user_name }}</strong></li>
            <li v-if="this.admin" class="nav-item m-2 text-right">
              <a class="mobile-link" @click="msgModal.show()">New Message</a>
            </li>
            <li class="nav-item m-2 text-right" v-if="this.admin"><router-link class="mobile-link" style="text-decoration: none" to="/archived">Archived</router-link></li>
      <li class="nav-item m-2 text-right"><router-link  class="mobile-link" style="text-decoration: none" to="/change-password">Change Password</router-link></li>
      <li class="nav-item m-2 text-right"><a class="mobile-link" @click="logout">Logout</a></li>
      </ul>
    </div>
    <div>
    <h1 class="text-center">Portage Medical Family Health Team Secure Messages</h1>
    <div class="text-center" v-if="err" >{{ err_msg }}</div>
    </div>
    <div v-if="!loading && !load_err" class="container-fluid p-4">
      <div v-if="messages != null">
      <div v-for="message in sortedMessages" :key="message.id" class="card my-2">
        <div :class="message.unread > 0 ? 'unread card-body py-2' : 'card-body' " class="card-body py-2">
          <div class="card-title m-0">
            <div class="row">
              <div class="col-lg-9 col-md-12">
                <h6><router-link :to="'/message/' + message.id" class="text-reset text-decoration-none stretched-link">{{ message.emails.join(', ')}}<span v-if="message.unread > 0" class="ms-1 badge bg-primary">{{message.unread}} Unread Message(s)</span></router-link></h6>
                <!-- <h6><router-link :to="'/message/' + message.id" class="text-reset text-decoration-none stretched-link">{{message.sender}}{{message.email}}<span v-if="message.unread > 0" class="ms-1 badge bg-primary">{{message.unread}} Unread Message(s)</span></router-link></h6> -->
                <div v-if="message.message != ''">
                  <span>Subject: {{truncateString(message.message, 150)}}</span>
                  <div><small class="text-muted">{{date_format(message.latest_date)}}</small></div>
                </div>
                <div v-else>
                  No messages
                </div>
              </div>
              <div class="col-lg-3 col-md-12 text-right">
                <div class="text-lg-end text-start"><small><em>{{message.sent}} Sent, {{message.received}} Received</em></small></div>
                <div v-if="admin" class="text-lg-end text-start mb-2"><small><em>{{message.unread_by_other}} Unread by {{message.email}}</em></small></div>
                <div v-if="admin" class="text-end">
                  <a @click="notifyMessage(message.id)" style="z-index: 2; position: relative; cursor: pointer;" class="me-2"  data-bs-toggle="tooltip" data-bs-placement="left" title="Tooltip on left">
                    <bell v-if="message.notify" class="bell" />
                    <mute v-else class="mute" />
                  </a>
                <a @click="archive(message.id)" class="btn btn-sm btn-primary" style="z-index: 2; position: relative"><small>Archive</small></a>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      </div>
      <div v-else class="text-center">
        You have no messages
      </div>
    </div>
    <div v-else-if="loading && !load_err" class="text-center container-fluid p-4">
      <div class="spinner-border text-primary" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <div v-else class="text-center container-fluid p-4">
      There was an error while trying to get messages.
    </div>
    <!-- <div v-show="this.admin" class="modal fade"  data-bs-backdrop="static" ref="userModal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Add a new user</h5>
          <button type="button" class="btn-close" @click="userModal.hide()" aria-label="Close"></button>
        </div>
        <div class="modal-body">
            <div class="mb-2">This will send them an email to create an account.</div>
          <div>
            <label for="email">Email:</label><input @focus="reset" v-model="email" class="form-control" type="email" id="email" />
            <small class="text-danger">{{createUserError}}</small>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="userModal.hide()">Cancel</button>
          <button type="button" class="btn btn-primary" @click="createUser">Create</button>
        </div>
      </div>
    </div>
  </div> -->
  <div v-show="this.admin" class="modal fade"  data-bs-backdrop="static" ref="msgModal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">New Message</h5>
          <button type="button" class="btn-close" @click="closeModal()" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div>
            <label for="email" class="mb-2">Enter the emails you will be sending the message to:</label>
            <div class="input-group mb-2">
            <input @focus="reset" v-model="email" class="form-control" type="email" id="email" />
            <a class="btn btn-success" @click="addEmails">Add</a>
            </div>
            <div class="mb-2"><small class="text-danger">{{newMsgError}}</small></div>
            <div>
            <span class="badge rounded-pill bg-secondary p-2 me-2" v-for="e in emails" :key="e">{{e}} <a class="delete-button" @click="removeEmail(e)"><x class="delete"/></a></span>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div class="me-auto">
          <input id="check-notify" type="checkbox" class="form-check-input me-2" v-model="notify" />
            <label for="check-notify" class="form-check-label">
              Notify me when messages are read
            </label>
          </div>
          <button type="button" class="btn btn-secondary" @click="msgModal.hide()">Cancel</button>
          <button type="button" class="btn btn-primary" @click="newMessage" >New Message</button>
        </div>
      </div>
    </div>
  </div>
    </div>
</template>

<script>
import authHeader from '../services/auth-header';
import { Modal } from 'bootstrap';
import bell from '@/assets/bell-solid.svg'
import mute from '@/assets/bell-slash-solid.svg'
import mobile from '@/assets/bars-solid.svg'
import x from '@/assets/xmark-solid.svg'
import moment from 'moment'
export default {
  name: 'Inbox',
  components: {
    bell,
    mute,
    mobile,
    x
  },
  data: function() {
    return {
      userId: '',
      admin: false,
      messages: null,
      msgModal: null,
      users: [],
      user_name: "",
      email: "",
      selected: "",
      newMsgError: "",
      createUserError: "",
      err: false,
      load_err: false,
      err_msg: "",
      loading: false,
      notify: false,
      emails:[],
      OpenIndicator: {
      render: createElement => createElement('span'),
    },
    }
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    sortedMessages: function() {
      // function compare(a, b) {
      //   if (a.latest < b.name)
      //     return -1;
      //   if (a.name > b.name)
      //     return 1;
      //   return 0;
      // }
    return this.messages.sort(function(a,b){return b.latest_date.getTime() - a.latest_date.getTime()});
    },
  },
  created() {
    // axios.get("/api/status", {withCredentials: true, headers: authHeader()})
    if (!this.loggedIn) {
      this.$router.push('/login');
    }

  },
  mounted() {
        axios.get("/api/status", {withCredentials: true, headers: authHeader()})
    this.msgModal = new Modal(this.$refs.msgModal)
    var self = this;
    this.loading = true;
    this.load_err = false;
    axios.get("/api/users/current", {withCredentials: true, headers: authHeader()}).then((res) => {
      this.userId = res.data.id
      this.admin = res.data.admin
      this.user_name = res.data.name
      if (res.data.new) {
        this.$router.push('/login');
      }
    }).then(() => {
      if(this.admin) {
        axios.get("/api/message_info/admin/" + this.userId , {withCredentials: true, headers: authHeader()})
        .then((response) => {
          self.messages = response.data
          if(self.messages.length > 0) {
          for(var i = 0; i < self.messages.length; i++)
          {
            self.messages[i].latest_date = new Date(self.messages[i].latest_date)
            
          }
          }
          self.loading = false
        })
      }
      else {
        axios.get("/api/message_info/user/" + this.userId , {withCredentials: true, headers: authHeader()})
        .then(function(response) {
          self.messages = response.data
          if(self.messages.length > 0) {
          for(var i = 0; i < self.messages.length; i++)
          {
            self.messages[i].latest_date = new Date(self.messages[i].latest_date)
            
          }
          }
          self.loading = false
        })
      }
        self.loading = false;
    })
    .catch((error) => {
      self.load_err = true
    })
  },
  destroyed() {
    this.msgModal.hide();
  },
  methods: {
      date_format: function (date) {
        return moment.utc(date).format('MMMM DD, YYYY [at] h:mm A');
      },
    logout: function() {
        this.$store.dispatch('auth/logout').then(
            () => {
            this.$router.push("/login")
            }
        )
    },
    createUser: function() {
      var header = authHeader()
      header['Access-Control-Allow-Origin'] = process.env.VUE_APP_API_BASE_URL
      header["Content-Type"] = "application/json"
      var self = this;
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if(re.test(String(this.email).toLowerCase())) {
        axios.post("/api/users", {email: this.email}, {withCredentials: true, headers: header}).then((res) => {
          self.userModal.hide()
          self.email = ""
        })
      }
      else {
        this.createUserError = "Not a valid email"
      }
    },
    openMsgModal(){
      var self = this;
      axios.get("/api/users" , {withCredentials: true, headers: authHeader()})
        .then(function(response) {
          
          self.users = response.data;
          self.msgModal.show();
        })
    },
    newMessage() {
      var header = authHeader()
      header['Access-Control-Allow-Origin'] = process.env.VUE_APP_API_BASE_URL
      header["Content-Type"] = "application/json"
      var self = this;
      if(this.emails.length > 0) {
        axios.post("/api/message_info", {admin_id: this.userId, user_emails: this.emails, notify: this.notify}, {withCredentials: true, headers: header}).then((res) => {
            self.$router.push("/message/" + res.data)
        }).catch(function(err) {
          self.newMsgError = err.response.data.error
        })
        
      }
      else {
        this.newMsgError = "Please add at least one email"
      }
      
    },
    dropdownOpen(VueSelect) {
      return VueSelect.search.length !== 0 && VueSelect.open

    },
    reset() {
      this.newMsgError = "";
      this.createUserError = "";
    },
    truncateString(str, n) {
      if (str.length > n) {
        return str.substring(0, n) + "...";
      } else {
        return str;
      }
    },
    archive(id) {
      var header = authHeader()
      header['Access-Control-Allow-Origin'] = process.env.VUE_APP_API_BASE_URL
      header["Content-Type"] = "application/json"
      var self = this;

        axios.post("/api/message_info/archive", {id: id, admin_id: self.userId}, {withCredentials: true, headers: header}).then((res) => {
          self.err = false;
          self.err_msg = ""
            for(var i = 0; i < this.messages.length; i++) {
              if(this.messages[i].id == id) {
                  this.messages.splice(i, 1);
                  break;
              }
            }
        }).catch((error) => {
          self.err = true;
          self.err_msg = error.response.data.error
        })
    },
    notifyMessage(id) {
      var header = authHeader()
      header['Access-Control-Allow-Origin'] = process.env.VUE_APP_API_BASE_URL
      header["Content-Type"] = "application/json"
      var self = this;

        axios.post("/api/message_info/notify", {id: id, admin_id: this.userId, admin: this.admin}, {withCredentials: true, headers: header}).then((res) => {
          self.err = false;
          self.err_msg = ""
            for(var i = 0; i < this.messages.length; i++) {
              if(this.messages[i].id == id) {
                  this.messages[i].notify = !this.messages[i].notify
              }
            }
        }).catch((error) => {
          self.err = true;
          self.err_msg = error.response.data.error
        })

    },
    addEmails() {
      this.newMsgError = ""
      var err = false;
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if(re.test(String(this.email).toLowerCase())) {
        if(this.emails.length > 0) {
          for(var i = 0; i < this.emails.length; i++) {
            if(this.emails[i] == this.email) {
              err = true;
              this.newMsgError = "Email already added"
              break;
            }
          }
        }
        if(!err) {
          this.emails.push(this.email)
          this.email = ""
        }
      } else {
        this.newMsgError = "Not a valid email"
      }
    },
    removeEmail(e) {
      for(var i = 0; i < this.emails.length; i++) {
        if(this.emails[i] == e) {
          this.emails.splice(i, 1);
          break;
      }
      }
    },
    closeModal() {
      this.email = ""
      this.newMsgError = ""
      this.msgModal.hide();
      this.emails = []
    }
  }
}
</script>
<style scoped>

.nb {
  display: flex;
  align-items: center;
  padding: 1rem;
}

.nb-right {
  width: 75%;
  float: right;
  text-align: right;
}

.nb-right a,
.mobile-link {
  font-weight: 600;
  cursor: pointer;
  color: #3660d1 !important;
  margin: 1rem;
  text-decoration: none;
}

.nb-right a:hover {
  color: #618cff !important
}

.nav-ul {
  list-style: none;
}

.nav-ul li {
  display: inline
}

.unread {
  background-color: #bfdeff;
}

.nav-img {
  max-width: 12rem;
}

.bell {
  /* color: #b8bbcc; */
  height: 22px;
  width: 22px;

}

.mute {
  color: #656770;
  height: 25px;
  width: 25px;

}

.phone {
  text-decoration: none;
  color: #477E80 !important;
  margin: 1rem;
}
.phone:hover {
  color: #83B9BA !important
}

.menu {
    color: #656770;
  height: 40px;
  width: 40px;
}

.delete {
  color: white;
  height: 13px;
  width: 13px;
}

.delete-button {
  cursor: pointer;
}

</style>
